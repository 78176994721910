<template>
    <div class="hrOverview">
        <div class="header">
            <div class="head-title">你好，{{userinfo.name}}</div>
            <div class="head-text">
                <div class="head-label">本月（{{monDate}}）:</div>
                <div class="head-item" v-for="item in topList">
                    <div class="tab-title">{{item.name}}
                    </div>

                    <!--:class="item.field=='waitingEntry' || item.field=='waitingQuit'?'tab-red':''"-->
                    <div class="tab-item" >
                        {{item.value}}
                    </div>
                </div>

            </div>
        </div>
        <div class="content">
            <div class="content-left">
                <div class="content-left-head" v-if="leftTopList">
                    <div style="display: flex" v-for="item in leftTopList">
                        <i class="iconfont"
                           :class="item.field=='waitingRelation'?'icon-lujing4920':'icon-lujing5548'"></i>
                        <div style="margin-right: 20px" @click="_changeUrl(item.path,item.tag)">
                            <div class="content-left-head-num">{{item.value}}</div>
                            <div class="content-left-head-text">{{item.name}}</div>
                        </div>
                    </div>

                </div>
                <div class="content-left-list" v-for="item in leftList">
                    <div class="content-left-list-title">
                        <!--icon-zu6027-->
                        <i class="iconfont" :class="item.icon"></i>
                        <span class="content-left-list-title-text">{{item.title}}</span>
                    </div>
                    <div class="content-left-list-info">
                        <div style="margin-right: 42px" v-for="listItem in item.list"
                             @click="_changeUrl(listItem.path,listItem.tag)">
                            <div class="content-left-list-info-num">{{listItem.value}}</div>
                            <div class="content-left-list-info-text">{{listItem.name}}</div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="content-right">
                <div class="content-right-title">重要事项</div>
                <div>
                    <a-tabs default-active-key="1" @change="callback">
                        <a-tab-pane key="1">
                            <span slot="tab">
                                @提醒
                                <!--<a-badge count="25"/>-->
                            </span>
                            <div class="comment">
                                <div class="comment-button">
                                    <a-button :type="tabRightKey==1?'primary':''"
                                              :class="tabRightKey==1?'':'search-button'" style="margin-right: 10px"
                                              @click="tabRight(1)">@我
                                    </a-button>
                                    <a-button :type="tabRightKey==2?'primary':''"
                                              :class="tabRightKey==2?'':'search-button'" style="margin-right: 10px"
                                              @click="tabRight(2)">跟进提醒
                                    </a-button>
                                </div>
                                <div v-if="RightMyList.length>0 && tabRightKey==1">
                                    <div class="comment-list">

                                        <div class="list">
                                            <!--<a-avatar class="list-avatar" :size="36" icon="user" />-->
                                            <a-timeline>
                                                <a-timeline-item v-for="item in RightMyList">
                                                    <a-avatar slot="dot" class="list-avatar" :size="36"
                                                              :src="item.Avatar" icon="user"/>
                                                    <div class="list-info">
                                                        <div class="user-item">
                                                            <div class="user-info">
                                                                <div class="user-info-name">{{item.CreateUserName}}
                                                                </div>
                                                                <div class="user-info-time">{{item.CreateTime}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="list-remark">{{item.Remark}}</div>
                                                        <div class="remark">
                                                            <div class="remark-content"
                                                                 v-if="item.hasOwnProperty('ToUserName') && item.ToUserName">
                                                                在 【{{item.CandidateName}}】的简历中{{item.ToUserName}}@了你
                                                            </div>
                                                            <div class="remark-content"
                                                                 v-if="item.hasOwnProperty('ToUserName') && !item.ToUserName">
                                                                在 【{{item.CandidateName}}】的简历中填写跟踪记录
                                                            </div>
                                                            <div class="remark-replay"
                                                                 @click="_resumeInfo(item.ResumeId,5)">回复
                                                            </div>
                                                        </div>
                                                    </div>

                                                </a-timeline-item>
                                            </a-timeline>
                                            <div class="check-more" @click="myMore">查看更多</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="RemindList.length>0 && tabRightKey==2">
                                    <div class="comment-list">

                                        <div class="list">
                                            <!--<a-avatar class="list-avatar" :size="36" icon="user" />-->
                                            <a-timeline>
                                                <a-timeline-item v-for="item in RemindList">
                                                    <a-avatar slot="dot" class="list-avatar" :size="36"
                                                              :src="item.Avatar" icon="user"/>
                                                    <div class="list-info">
                                                        <div class="user-item">
                                                            <div class="user-info">
                                                                <div class="user-info-name">{{item.CreateUserName}}
                                                                </div>
                                                                <div class="user-info-time">{{item.CreateTime}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="list-remark">{{item.Remark}}</div>
                                                        <div class="remark">
                                                            <div class="remark-content"
                                                                 v-if="item.hasOwnProperty('ToUserName') && item.ToUserName">
                                                                在 【{{item.CandidateName}}】的简历中{{item.ToUserName}}@了你
                                                            </div>
                                                            <div class="remark-content"
                                                                 v-if="item.hasOwnProperty('ToUserName') && !item.ToUserName">
                                                                在 【{{item.CandidateName}}】的简历中填写跟踪记录
                                                            </div>
                                                            <div class="remark-replay"
                                                                 @click="_resumeInfo(item.ResumeId,5)">回复
                                                            </div>
                                                        </div>
                                                    </div>

                                                </a-timeline-item>
                                            </a-timeline>
                                            <div class="check-more" @click="myMore">查看更多</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2">
                            <span slot="tab">
                                需求相关
                                <!--<a-badge count="25"/>-->
                            </span>
                            <div class="comment-list">
                                <div class="comment-button">
                                    <a-button style="margin-right: 10px" :type="CheckStatus==1?'primary':''"
                                              :class="CheckStatus==1?'':'search-button'" @click="_CheckStatus(1)">需求通过
                                    </a-button>
                                    <a-button style="margin-right: 10px" :type="CheckStatus==2?'primary':''"
                                              :class="CheckStatus==2?'':'search-button'" @click="_CheckStatus(2)">需求驳回
                                    </a-button>

                                    <a-button style="margin-right: 10px" :type="NeedType ==1?'primary':''"
                                              :class="NeedType == 1?'':'search-button'" @click="_NeedType(1)">我负责的
                                    </a-button>
                                    <a-button style="margin-right: 10px" :type="NeedType == 2?'primary':''"
                                              :class="NeedType ==2?'':'search-button'" @click="_NeedType(2)">我协助的
                                    </a-button>
                                </div>
                                <div class="list" v-if="needList.length>0">
                                    <a-timeline>
                                        <a-timeline-item v-for="item in needList">
                                            <a-avatar slot="dot" class="list-avatar" :size="36" :src="item.Avatar"
                                                      icon="user"/>
                                            <div class="list-info">
                                                <div class="user-item">
                                                    <div class="user-info">
                                                        <div class="user-info-name">{{item.CreateUserName}}</div>
                                                        <div class="user-info-time">{{item.CreateTime}}</div>
                                                    </div>
                                                </div>
                                                <!--<div class="list-remark">{{item.Remark}}</div>-->
                                                <div class="remark interview-remark">
                                                    {{item.Field+':'+item.DepartmentName+'|'+item.Number+'名'+'|'+item.PositionName+'|'+item.DemandUserName}}   {{NeedType?'你被设置为':''}} {{NeedType==1?'招聘负责人,':NeedType==2?'协助招聘负责人,':''}} {{NeedType?'请尽快开始招聘':''}}
                                                    <span class="location-color"
                                                          @click="_resumeNeed(item.Pid)">查看详情</span>
                                                </div>
                                            </div>

                                        </a-timeline-item>
                                    </a-timeline>
                                    <div class="check-more" @click="needMore">{{needList.length==0?'暂无数据':'查看更多'}}</div>
                                </div>

                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="3">
                            <span slot="tab">
                                面试相关
                                <!--<a-badge count="25"/>-->
                            </span>
                            <div class="comment-list">
                                <div class="comment-button">
                                    <a-button style="margin-right: 10px" :type="interviewFeedback==1?'primary':''"
                                              :class="interviewFeedback==1?'':'search-button'" @click="_interviewFeedback()">
                                        面试反馈
                                    </a-button>
                                    <a-button style="margin-right: 10px" :type="interviewArrangement==1?'primary':''"
                                              :class="interviewArrangement==1?'':'search-button'" @click="_interviewArrangement()">
                                        安排面试
                                    </a-button>
                                    

                                    <a-button style="margin-right: 10px" :type="candidateType==1?'primary':''"
                                    :class="candidateType==1?'':'search-button'" @click="_candidateType(1)">
                                     候选人接受
                                    </a-button>
                                    <a-button style="margin-right: 10px" :type="candidateType==2?'primary':''"
                                    :class="candidateType==2?'':'search-button'" @click="_candidateType(2)">
                                      候选人拒绝
                                    </a-button>

                                    <!--<a-button style="margin-right: 10px" :type="interviewType==1?'primary':''"-->
                                              <!--:class="interviewType==1?'':'search-button'" @click="_interviewType(1)">-->
                                        <!--审批通过-->
                                    <!--</a-button>-->
                                    <!--<a-button style="margin-right: 10px" :type="interviewType==2?'primary':''"-->
                                              <!--:class="interviewType==2?'':'search-button'" @click="_interviewType(2)">-->
                                        <!--审批驳回-->
                                    <!--</a-button>-->



                                </div>
                                <div class="list" v-if="InterviewList.length >0">
                                    <a-timeline>
                                        <a-timeline-item v-for="item in InterviewList">
                                            <a-avatar slot="dot" class="list-avatar" :size="36" :src="item.Avatar"
                                                      icon="user"/>
                                            <div class="list-info">
                                                <div class="user-item">
                                                    <div class="user-info">
                                                        <div class="user-info-name">{{item.CreateUserName}}</div>
                                                        <div class="user-info-time">{{item.CreateTime}}</div>
                                                    </div>
                                                </div>
                                                <!--<div class="list-remark">{{item.Remark}}</div>-->
                                                <div class="remark interview-remark">
                                                    候选人: 【<span class="location-color" @click="_resumeInfo(item.Pid,2)">{{item.candidateName}}</span>】   <a-button type="primary" style="margin-right: 5px" size="small">{{item.Field}}</a-button>   原因:{{item.Remark}}
                                                </div>
                                            </div>

                                        </a-timeline-item>
                                    </a-timeline>
                                    <div class="check-more" @click="InterviewMore">查看更多</div>
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="4">
                            <span slot="tab">
                                offer相关
                                <!--<a-badge count="25"/>-->
                            </span>

                            <div class="comment-list">
                                <div class="comment-button">

                                    <a-button style="margin-right: 10px" :type="CandidateStatus==1?'primary':''"
                                              :class="CandidateStatus==1?'':'search-button'"
                                              @click="_CandidateStatus(1)">   候选人接受offer
                                    </a-button>
                                    <a-button style="margin-right: 10px" :type="CandidateStatus==2?'primary':''"
                                              :class="CandidateStatus==2?'':'search-button'"
                                              @click="_CandidateStatus(2)">  候选人拒绝offer
                                    </a-button>
                                    
                                    <a-button style="margin-right: 10px" :type="ApprovalStatus==1?'primary':''"
                                              :class="ApprovalStatus==1?'':'search-button'" @click="_ApprovalStatus(1)">
                                        审批通过
                                    </a-button>
                                    <a-button style="margin-right: 10px" :type="ApprovalStatus==2?'primary':''"
                                              :class="ApprovalStatus==2?'':'search-button'" @click="_ApprovalStatus(2)">
                                        审批驳回
                                    </a-button>


                                </div>

                                <div class="list" v-if="offerList.length>0">
                                    <a-timeline>
                                        <a-timeline-item v-for="item in offerList">
                                            <a-avatar slot="dot" class="list-avatar" :size="36" :src="item.Avatar"
                                                      icon="user"/>
                                            <div class="list-info">
                                                <div class="user-item">
                                                    <div class="user-info">
                                                        <div class="user-info-name">{{item.CreateUserName}}</div>
                                                        <div class="user-info-time">{{item.CreateTime}}</div>
                                                    </div>
                                                </div>
                                                <!--<div class="list-remark">{{item.Remark}}</div>-->
                                                <div class="remark interview-remark">
                                                    候选人: 【<span class="location-color" @click="_resumeInfo(item.Pid,4)">{{item.CandidateName}}</span>】  <a-button type="primary" style="margin-right: 5px" size="small">{{item.Field}}</a-button> {{item.Remark}}

                                                </div>
                                            </div>

                                        </a-timeline-item>
                                    </a-timeline>
                                    <div class="check-more" @click="offerMore">查看更多</div>
                                </div>
                            </div>
                        </a-tab-pane>
                        <!--<a-tab-pane key="5">-->
                        <!--<span slot="tab">-->
                        <!--推荐相关-->
                        <!--<a-badge count="25"/>-->
                        <!--</span>-->
                        <!--Content of Tab Pane 3-->
                        <!--</a-tab-pane>-->
                        <!--<a-tab-pane key="6">-->
                        <!--<span slot="tab">-->
                        <!--其他-->
                        <!--<a-badge count="25"/>-->
                        <!--</span>-->

                        <!--Content of Tab Pane 3-->
                        <!--</a-tab-pane>-->

                    </a-tabs>
                </div>
            </div>
        </div>

        <!--简历信息-->
        <a-drawer
                :title="ResumeId ? '简历信息' : '添加简历'"
                width="1000"
                :closable="false"
                :visible="visible"
                @close="onClose"

        >
            <div style="margin-bottom: 60px">
                <resumeInfo v-if="visible" :Id="ResumeId" :ResumeKey="ResumeKey" @visible="onClose"></resumeInfo>
            </div>
        </a-drawer>
        <!--需求详情-->
        <a-drawer
                title="招聘需求"
                width="1000"
                :closable="false"
                :visible="Needvisible"
                @close="NeedonClose"
        >
            <div style="margin-bottom: 60px">
                <needsContentAction
                        v-if="Needvisible"
                        :Id="NeedId"
                        @visible="NeedonClose"
                ></needsContentAction>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import resumeInfo from 'pages/Hr/resumeInfo';
    import needsContentAction from 'pages/Hr/needsContentAction'

    export default {
        name: "hrOverview",
        components: {resumeInfo, needsContentAction},
        data() {
            return {
                monDate: '',
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                visible: false,
                ResumeId: '',
                ResumeKey: '',
                topList: [],
                leftList: [],
                leftTopList: '',
                RightMyList: [],
                tabRightKey: 1,
                InterviewList: [],
                offerList: [],
                needList: [],
                RemindList: [],
                pagesize: 10,
                MyPage: 1,
                RemindPage: 1,
                InterviewPage: 1,
                offerPage: 1,
                needPage: 1,
                CheckStatus: '',
                NeedType: '',
                interviewType: '',
                candidateType: '',
                CandidateStatus: '',
                ApprovalStatus: '',
                NeedId: '',
                Needvisible: false,
                interviewFeedback:0,
                interviewArrangement:0,
            }
        },
        created() {
            this.overviewTop();
            this.overviewLeft();
            this.RightMy();
        },
        methods: {
            _resumeNeed(id) {
                this.NeedId = id;
                this.Needvisible = true;
            },
            NeedonClose() {
                this.NeedId = '';
                this.Needvisible = false;
            },
            overviewTop() {
                let self = this;
                this.$axios.get(12925, {}, res => {
                    if (res.data.code == 0) {
                        self.topList = res.data.data;
                    }
                })
            },
            overviewLeft() {
                let self = this;
                this.$axios.get(12926, {}, res => {
                    if (res.data.code == 0) {
                        for (let i = 1; i < res.data.data.length; i++) {
                            let arr = res.data.data[i];
                            self.leftList.push(arr)
                        }
                    }
                    self.leftTopList = res.data.data[0].list;
                    self.monDate = res.data.date;
                })
            },


            tabRight(i) {
                if (this.tabRightKey == i) {
                    return false;
                }
                this.RightMyList = [];
                if (i == 1) {
                    this.RightMy();
                } else {
                    this.RightRemind();
                }
                this.tabRightKey = i;
            },
            myMore() {
                if (this.tabRightKey == 1) {
                    this.MyPage += 1;
                    this.RightMy();
                } else {
                    this.RemindPage += 1;
                    this.RightRemind();
                }
            },
            //跟进提醒
            //@我
            RightMy() {
                let self = this;
                this.$axios.get(12927, {
                    pagesize: self.pagesize,
                    p: self.MyPage,
                }, res => {
                    if (res.data.code == 0) {
                        if (self.RightMyList.length == 0) {
                            self.RightMyList = res.data.data;
                        } else {
                            for (let i = 0; i < res.data.data.length; i++) {
                                self.RightMyList.push(res.data.data[i]);
                            }
                        }

                    } else {
                        this.$message.error(res.data.msg)
                    }

                })
            },
            RightRemind() {
                let self = this;
                this.$axios.get(12928, {
                    pagesize: self.pagesize,
                    p: self.RemindPage,
                }, res => {
                    if (res.data.code == 0) {
                        if (self.RemindList.length == 0) {
                            self.RemindList = res.data.data;
                        } else {
                            for (let i = 0; i < res.data.data.length; i++) {
                                self.RemindList.push(res.data.data[i]);
                            }
                        }
                    } else {
                        this.$message.error(res.data.msg)
                    }

                })
            },
            //面试相关
            InterviewMore() {
                this.InterviewPage += 1;
                this.getInterview();
            },
            _interviewType(i) {
                if (i == this.interviewType) {
                    this.interviewType = '';
                } else {
                    this.interviewType = i;
                }
                this.InterviewList = [];
                this.InterviewPage = 1;
                this.getInterview();
            },
            _interviewFeedback(){
                if(this.interviewFeedback ==1){
                    this.interviewFeedback =0;
                }else{
                    this.interviewFeedback =1;
                }
                this.InterviewList = [];
                this.InterviewPage = 1;
                this.getInterview();
            },
            _interviewArrangement(){
                if(this.interviewArrangement ==1){
                    this.interviewArrangement = 0;
                }else{
                    this.interviewArrangement =1;
                }
                this.InterviewList = [];
                this.InterviewPage = 1;
                this.getInterview();
            },
            _candidateType(i) {
                if (i == this.candidateType) {
                    this.candidateType = '';
                } else {
                    this.candidateType = i;
                }
                this.InterviewList = [];
                this.InterviewPage = 1;
                this.getInterview();
            },
            getInterview() {
                let self = this;
                this.$axios.get(12929, {
                    pagesize: self.pagesize,
                    p: self.InterviewPage,
                    interviewType: self.interviewType,
                    candidateType: self.candidateType,
                    interviewFeedback:self.interviewFeedback,
                    interviewArrangement:self.interviewArrangement,
                }, res => {
                    if (res.data.code == 0) {
                        if (self.InterviewList.length == 0) {
                            self.InterviewList = res.data.data;
                        } else {
                            for (let i = 0; i < res.data.data.length; i++) {
                                self.InterviewList.push(res.data.data[i]);
                            }
                        }
                    } else {
                        this.$message.error(res.data.msg)
                    }

                })
            },
            //offer相关
            offerMore() {
                this.offerPage += 1;
                this.getOffer();

            },
            _ApprovalStatus(i) {
                if (i == this.ApprovalStatus) {
                    this.ApprovalStatus = '';
                } else {
                    this.ApprovalStatus = i;
                }
                this.offerList = [];
                this.offerPage = 1;
                this.getOffer();
            },
            _CandidateStatus(i) {
                if (i == this.CandidateStatus) {
                    this.CandidateStatus = '';
                } else {
                    this.CandidateStatus = i;
                }
                this.offerList = [];
                this.offerPage = 1;
                this.getOffer();
            },
            getOffer() {
                let self = this;
                this.$axios.get(12930, {
                    pagesize: self.pagesize,
                    p: self.offerPage,
                    ApprovalStatus: self.ApprovalStatus,
                    CandidateStatus: self.CandidateStatus,
                }, res => {
                    if (res.data.code == 0) {

                        if (self.offerList.length == 0) {
                            self.offerList = res.data.data;
                        } else {
                            for (let i = 0; i < res.data.data.length; i++) {
                                self.offerList.push(res.data.data[i]);
                            }
                        }
                    } else {
                        this.$message.error(res.data.msg)
                    }

                })
            },
            //需求相关
            needMore() {
                this.needPage += 1;
                this.getNeed();
            },
            _NeedType(val) {
                if (this.NeedType == val) {
                    this.NeedType = '';
                } else {
                    this.NeedType = val;
                }
                this.needList = [];
                this.needPage = 1;
                this.getNeed();
            },
            _CheckStatus(i) {
                if (i == this.CheckStatus) {
                    this.CheckStatus = '';
                } else {
                    this.CheckStatus = i;
                }
                this.needList = [];
                this.needPage = 1;
                this.getNeed();
            },
            getNeed() {
                let self = this;
                this.$axios.get(12931, {
                    pagesize: self.pagesize,
                    p: self.needPage,
                    CheckStatus: self.CheckStatus,
                    NeedType: self.NeedType,
                }, res => {
                    if (res.data.code == 0) {
                        if (self.needList.length == 0) {
                            self.needList = res.data.data;
                        } else {
                            for (let i = 0; i < res.data.data.length; i++) {
                                self.needList.push(res.data.data[i]);
                            }
                        }
                    } else {
                        this.$message.error(res.data.msg)
                    }

                })
            },
            callback(key) {
                if (key == 1) {
                    //@提醒
                    if (this.RightMyList.length == 0) {
                        if (this.tabRightKey == 1) {
                            this.RightMy();
                        } else {
                            this.RightRemind();
                        }
                    }
                } else if (key == 2) {
                    if (this.needList.length == 0) {
                        this.getNeed();
                    }
                } else if (key == 3) {
                    //面试相关
                    if (this.InterviewList.length == 0) {
                        this.getInterview();
                    }
                } else if (key == 4) {
                    if (this.offerList.length == 0) {
                        this.getOffer();
                    }
                }
            },
            _resumeInfo(id, key) {
                console.log(id);
                this.ResumeId = id;
                this.ResumeKey = key + '';
                this.visible = true;
            },
            onClose() {
                this.ResumeId = '';
                this.ResumeKey = "";
                this.visible = false
            },
            _changeUrl(path, value) {
                if (!path) {
                    return false;
                }
                this.$router.push({path: path, query: {overviewTag: value}})
            },
        }

    }
</script>

<style scoped lang="less">
    .hrOverview {

        padding: 20px;
    }

    .content {
        display: flex;
        min-height: 600px;
        .content-left {
            width: 350px;
            margin-right: 15px;
            background: #ffffff;
            box-shadow: 0px 5px 12px rgba(10, 49, 83, 0.08);
            padding: 18px 16px 10px 16px;
            .content-left-head {
                border: 1px solid #D6D6D6;
                border-radius: 3px;
                display: flex;
                align-items: center;
                padding: 16px 0px 26px 20px;
                .icon-lujing4920 {
                    display: block;
                    color: #707cff;
                    font-size: 28px;
                    margin-right: 10px;
                }
                .icon-lujing5548 {
                    display: block;
                    color: #707cff;
                    font-size: 28px;
                    margin-right: 10px;
                    color: #707cff;
                }
                .content-left-head-num {
                    color: #242424;
                    font-size: 17px;
                }
                .content-left-head-text {
                    color: #242424;
                    font-size: 13px;
                }
            }
            .content-left-list {
                margin-top: 10px;
                border: 1px solid #D6D6D6;
                .content-left-list-title {
                    height: 32px;
                    padding-left: 16px;
                    background: #F9F9FA;
                    display: flex;
                    align-items: center;
                    .content-left-list-title-text {
                        margin-left: 10px;
                        color: #242424;
                        font-size: 13px;
                    }
                }
                .content-left-list-info {
                    padding: 5px 5px 35px 16px;
                    display: flex;
                    .content-left-list-info-num {
                        color: #242424;
                        font-size: 17px;
                    }
                    .content-left-list-info-text {
                        color: #242424;
                        font-size: 13px;
                    }
                }
            }

        }
        .content-right {
            flex: 1;
            background: #ffffff;
            box-shadow: 0px 5px 12px rgba(10, 49, 83, 0.08);
            padding: 25px 10px 45px 0px;
            .content-right-title {
                color: #242424;
                font-size: 20px;
                margin-left: 16px;
                margin-bottom: 16px;
            }

        }
    }

    .comment-list {
        .comment-day {
            display: flex;
            align-items: center;
            width: 97px;
            height: 35px;
            background: #F3F3F4;
            border-radius: 0px 18px 18px 0px;
            margin-top: 18px;
            margin-bottom: 14px;
            cursor: pointer;
            .down-circle {
                background: #ffffff;
                color: #000000;
                font-size: 12px;
                border: 1px solid #E7E8EB;
                margin-left: 30px;
                border-radius: 50%;
                margin-right: 8px;
            }
        }
        .list {
            margin-left: 25px;
            margin-top: 30px;
            .list-info {
                margin-left: 14px;
                .user-item {

                    .user-info {
                        display: flex;
                        align-items: center;
                        .user-info-name {
                            color: #000000;
                            font-size: 14px;
                            /*font-weight: 500;*/
                            margin-right: 12px;
                        }
                        .user-info-time {
                            color: #999999;
                            font-weight: 300;
                            font-size: 12px;
                        }
                    }
                }
                .list-remark {
                    display: inline-block;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    background: #FFFFFF;
                    border: 1px solid #DDDFE3;
                    border-radius: 5px;
                    padding: 6px 20px;
                }
                .remark {
                    display: flex;
                    align-items: center;
                    .remark-content {
                        color: #999999;
                        font-size: 12px;
                    }
                    .remark-replay {
                        color: #2994FF;
                        font-size: 12px;
                        margin-left: 6px;
                        cursor: pointer;
                    }
                }
                .interview-remark {
                    color: #242424;
                    font-size: 15px;
                    margin-top: 5px;
                }
            }

        }
    }

    .comment-button {
        margin-left: 18px;
    }

    .header {
        padding: 50px 36px 36px 36px;
        box-shadow: 0px 5px 12px rgba(10, 49, 83, 0.08);
        background: #ffffff;
        margin-bottom: 15px;
        .head-title {
            color: #242424;
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 24px;
        }
        .head-text {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .head-label {
                color: #999999;
                font-size: 14px;
                margin-right: 8px;
            }
            .head-item {
                display: flex;
                align-items: center;
                .tab-title {
                    color: #090909;
                    font-size: 14px;
                    margin-right: 10px;
                }
                .tab-item {
                    color: #2994FF;
                    font-size: 20px;
                    margin-right: 20px;
                }
            }
        }
    }

    .tab-red {
        color: #FF0000 !important;
    }

    .check-more {
        cursor: pointer;
    }

    .search-button {
        color: #666666;
        background: #F4F4F5;
    }

    .location-color {
        color: #2994ff;
        cursor: pointer;
        margin-right: 5px;
        margin-left: 5px;
    }
    .NeedType{
        color: #2994ff;
    }
</style>